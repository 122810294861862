import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/layout'

const TagItem = styled.ul`
  list-style: none;
  li {
    display: inline-block;
    a {
      border: 3px solid #AABBCC;
      border-top: 0px;
      border-left: 0px;
      border-radius: 10px;
      text-decoration: none;
      padding: 5px 15px;
      margin-bottom: 5px;
      margin-right: 5px;
      color: #445566;
    }
  }
`

export default ({ data, pageContext }) => {
  return (
    <Layout>
      <h3>List of Tags</h3>
      <TagItem>
        {pageContext.tags.map(tag => {
          const path = `/tags/${tag}`

          return (
            <li key={tag}>
              <Link to={path}>{tag}</Link>
            </li>
          )
        })}
      </TagItem>
    </Layout>
  )
}
